import React from "react";
import {
  makeStyles,
  Skeleton,
  SkeletonItem,
} from "@fluentui/react-components";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  skeleton: {
    display: "flex",
    flexDirection: "column",
    marginTop: "8px",
  },
  skeletonItem: {
    marginBottom: "4px",
  },
});

const LoadingItems = ({ number }) => {
  const styles = useStyles();

  return (
    <Skeleton className={styles.skeleton}>
      {[...Array(number)].map((_, index) => (
        <SkeletonItem className={styles.skeletonItem} key={index} />
      ))}
    </Skeleton>
  );
};

LoadingItems.propTypes = {
  number: PropTypes.number,
};

export default LoadingItems;
