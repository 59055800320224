import React, { createContext, useEffect, useReducer, useState } from "react";
import { language, connectionUrl, userId } from "../commands/commands.js";
import { refreshAccessToken } from "./authentication";
import { DEFAULT_LANGUAGE, ACCESS_TOKEN_REFRESH_INTERVAL } from "./constants.js";
import Login from "./components/Login";

export const ConnectionUrlContext = createContext({});
export const LanguageContext = createContext("");
export const AuthUserContext = createContext({});
export const AuthUserDispatchContext = createContext(() => { });

export const ContextsProvider = ({ children }) => {
  const [languageCtx, setLanguageCtx] = useState(language ?? DEFAULT_LANGUAGE);
  const [connectionUrlCtx, setConnectionUrlCtx] = useState(connectionUrl ?? "");
  const [authUser, dispatchAuthUser] = useReducer(authUserReducer, {
    expiresAt: sessionStorage.getItem("expiresAt"),
    accessToken: sessionStorage.getItem("accessToken"),
    refreshToken: sessionStorage.getItem("refreshToken"),
  });

  // Refresh the access token
  useEffect(() => {
    console.log("authUser log at: ", new Date(), authUser);
    if (!authUser.accessToken || !authUser.refreshToken) {
      return;
    }
    if (new Date(authUser.expiresAt) < new Date()) {
      console.log("Access token expired.");
      clearInterval();
      return;
    } else if (new Date(authUser.expiresAt) - new Date() < ACCESS_TOKEN_REFRESH_INTERVAL) {
      handleTokenRefresh();
      return;
    }

    sessionStorage.setItem("expiresAt", authUser.expiresAt);
    sessionStorage.setItem("accessToken", authUser.accessToken);
    sessionStorage.setItem("refreshToken", authUser.refreshToken);

    setInterval(async () => handleTokenRefresh(), ACCESS_TOKEN_REFRESH_INTERVAL);
  }, [authUser])

  function handleTokenRefresh() {
    refreshAccessToken(authUser.accessToken, authUser.refreshToken, connectionUrl)
      .then((data) => {
        dispatchAuthUser({ type: 'set', payload: data });
        console.log("Refreshed access token.");
      })
      .catch((err) => {
        console.log("Refresh token failed. API Server Session lost.: ", err);
      });
  }

  return (
    <LanguageContext.Provider value={languageCtx}>
      <ConnectionUrlContext.Provider value={connectionUrlCtx}>
        <AuthUserContext.Provider value={authUser}>
          <AuthUserDispatchContext.Provider value={dispatchAuthUser}>
            {new Date(authUser.expiresAt) > new Date() ? (
              children
            ) : (
              <Login
                setLanguage={setLanguageCtx}
                setConnectionUrlCtx={setConnectionUrlCtx}
                connUrl={connectionUrl}
                uId={userId}
              />
            )}
          </AuthUserDispatchContext.Provider>
        </AuthUserContext.Provider>
      </ConnectionUrlContext.Provider>
    </LanguageContext.Provider>
  );
}

function authUserReducer(state, action) {
  switch (action.type) {
    case 'set':
      return action.payload;
    case 'remove':
      return {
        expiresAt: null,
        accessToken: null,
        refreshToken: null,
      };
    default:
      throw new Error("Invalid action type.");
  }
}
