import * as React from "react";
import { useState, useContext } from "react";
import {
  makeStyles,
  tokens,
  shorthands,
  Input,
  RadioGroup,
  Radio,
  Subtitle2,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  MenuButton,
  Image,
} from "@fluentui/react-components";
import { Search24Regular } from "@fluentui/react-icons";
import translate from "../translations/intelliact-dictionary/translator.mjs";
import { LanguageContext, AuthUserContext, ConnectionUrlContext, AuthUserDispatchContext } from "../contexts";
import { searchEntities } from "../authenticated-requests.js";
import { SEARCH_RESULTS_LIMIT, ENTITY_TYPES } from "../constants";
import LoadingItems from "./LoadingItems";
import PropTypes from "prop-types";
import businessIcon from "../../assets/business.png";
import personIcon from "../../assets/person.png";
import contractIcon from "../../assets/contract.png";

const useStyles = makeStyles({
  categoryTitle: {
    color: tokens.colorNeutralForeground2BrandHover,
    ...shorthands.margin("4px", "0", "4px", "0"),
  },
  search: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "8px",
    ...shorthands.margin("4px", "4px", "0", "0"),
  },
  searchBox: {
    display: "flex",
    flexDirection: "row",
    "> span": {
      flexGrow: 2,
    },
  },
  menuItem: {
    "> span": {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  },
  menuIcon: {
    width: "24px",
    paddingRight: "8px",
  },
  results: {
    ...shorthands.margin("4px", "8px", "0", "20px"),
  },
});

const EntitySearch = ({
  searchResults,
  setSearchResults,
  selectedEntity,
  handleEntitySelection,
  setCategoriesOpen,
}) => {
  const language = useContext(LanguageContext);
  const user = useContext(AuthUserContext);
  const connectionUrl = useContext(ConnectionUrlContext);
  const dispatchAuthUser = useContext(AuthUserDispatchContext);

  const styles = useStyles();

  const searchTxt = translate(language, "intelliactGroup", "search");
  const searchIn = translate(language, "intelliactGroup", "searchIn");

  const [query, setQuery] = useState("");
  const [selectedEntityType, setSelectedEntityType] = useState(ENTITY_TYPES[0]);
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") search();
  };

  function search() {
    if (query === "") return;
    if (!selectedEntityType) return;

    setCategoriesOpen([]);
    setLoadingSearchResults(true);

    searchEntities(user.accessToken, connectionUrl, selectedEntityType.value, query)
      .then((data) => {
        setSearchResults(data.slice(0, SEARCH_RESULTS_LIMIT));
        setLoadingSearchResults(false);
      })
      .catch((err) => {
        setLoadingSearchResults(false);
        console.error("Failed to search, ", err);
        if (err.message === "Unauthorized") {
          dispatchAuthUser({ type: "remove" });
        }
      });
  }

  return (
    <div className={styles.search}>
      <Subtitle2 className={styles.categoryTitle}>{searchTxt}</Subtitle2>
      <div className={styles.searchBox}>
        <Input
          value={query}
          onChange={(e, data) => setQuery(data.value)}
          placeholder={searchIn + " " + translate(language, "intelliactGroup", selectedEntityType.title)}
          onKeyDown={handleKeyDown}
          contentBefore={<Search24Regular />}
        />
        <Menu>
          <MenuTrigger disableButtonEnhancement>
            <MenuButton />
          </MenuTrigger>
          <MenuPopover>
            <MenuList>
              {ENTITY_TYPES.map(entityType => (
                <MenuItem
                  className={styles.menuItem}
                  key={entityType.value}
                  onClick={() => setSelectedEntityType(entityType)}
                >
                  <Image
                    className={styles.menuIcon}
                    src={`../../assets/${entityType.icon}`}
                    alt={entityType.value}
                  />
                  {translate(language, "intelliactGroup", entityType.title)}
                </MenuItem>
              ))}
            </MenuList>
          </MenuPopover>
        </Menu>
      </div>

      {loadingSearchResults ? (
        <LoadingItems className={styles.results} number={3} />
      ) : (
        <RadioGroup className={styles.results}
          value={selectedEntity?.entityID}
          onChange={(e, data) => handleEntitySelection(data.value)}
        >
          {searchResults.map((result) => (
            <Radio
              key={"result_" + result.entityID}
              className={styles.categoryEntity}
              value={result.entityID}
              label={result.entityName}
            />
          ))}
        </RadioGroup>
      )}
    </div>
  );
};

EntitySearch.propTypes = {
  searchResults: PropTypes.array,
  setSearchResults: PropTypes.func,
  selectedEntity: PropTypes.object,
  handleEntitySelection: PropTypes.func,
  setCategoriesOpen: PropTypes.func,
};

export default EntitySearch;
