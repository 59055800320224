import * as React from "react";
import { useContext } from "react";
import { Image, makeStyles, Link } from "@fluentui/react-components";
import corporatekLogo from "../../assets/corporatek-logo.png";
import translate from "../translations/login-dictionary/translator.mjs";
import { LanguageContext } from "../contexts";

const useStyles = makeStyles({
  footer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    paddingLeft: "10px",
    fontSize: "10px",
  },
});

const Footer = () => {
  const styles = useStyles();
  const language = useContext(LanguageContext);

  const copyrightInfo = translate(language, "loginGroup", "copyrightInfo");

  return (
    <section className={styles.footer}>
      {copyrightInfo}
      <Link href="https://www.corporatek.com" title="www.corporatek.com" target="_blank">
        <Image src={corporatekLogo} alt="Corporatek logo" />
      </Link>
    </section>
  );
};

export default Footer;
