import * as React from "react";
import { useState, useContext, useEffect } from "react";
import {
  makeStyles,
  shorthands,
  Body1Strong,
  Button,
  Checkbox,
} from "@fluentui/react-components";
import { ArrowLeft24Regular } from "@fluentui/react-icons";
import translate from "../translations/intelliact-dictionary/translator.mjs";
import { LanguageContext, AuthUserContext, ConnectionUrlContext, AuthUserDispatchContext } from "../contexts";
import { addAttachmentToEmail } from "../../commands/commands.js";
import { getRecentEntities, getFavoriteEntities, getDocuments, downloadDocument } from "../authenticated-requests.js";
import { SHORTCUT_CATEGORIES } from "../constants.js";
import Categories from "./Categories.jsx";
import EntitySearch from "./EntitySearch";
import FeedbackMessage, { initialFeedbackMessage } from "./FeedbackMessage";

const useStyles = makeStyles({
  mainDiv: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    ...shorthands.margin("0", "12px", "12px", "12px"),
  },
  entitySelection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
  },
  documentView: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
  },
  documentViewNavbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    "> button": {
      marginRight: "8px",
    },
    width: "100%",
    position: "fixed",
    backgroundColor: "white",
    zIndex: 10,
  },
  selectAllCheckbox: {
    marginTop: "30px",
  },
  documents: {
    marginLeft: "28px",
    marginBottom: "48px",
    "> span": {
      width: "100%",
    },
  },
  btnAndMessage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    position: "fixed",
    bottom: "0",
    backgroundColor: "white",
  },
  attach: {
    ...shorthands.marginBlock("16px", "16px"),
  },
  instruction: {
    marginBottom: "8px",
  },
});

const ComposeEmailPane = () => {
  const language = useContext(LanguageContext);
  const user = useContext(AuthUserContext);
  const connectionUrl = useContext(ConnectionUrlContext);
  const dispatchAuthUser = useContext(AuthUserDispatchContext);

  const [searchResults, setSearchResults] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [showDocumentView, setShowDocumentView] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const [feedbackMessage, setFeedbackMessage] = useState(initialFeedbackMessage);

  const styles = useStyles();

  const selectEntityToSearchDoc = translate(language, "intelliactGroup", "selectEntityToSearchDoc");
  const attach = translate(language, "intelliactGroup", "attachFile");
  const attachSuccess = translate(language, "intelliactGroup", "attachSuccess");
  const attachError = translate(language, "intelliactGroup", "attachError");

  const [categories, setCategories] = useState(SHORTCUT_CATEGORIES);
  const [categoriesOpen, setCategoriesOpen] = useState(categories.map(cat => cat.value));

  useEffect(() => {
    getRecentEntities(user.accessToken, connectionUrl)
      .then((data) => {
        setCategories((prev) => {
          return prev.map(cat => {
            if (cat.value === "recent") {
              return {
                ...cat,
                entities: data,
              }
            }
            return cat;
          })
        });
      })
      .catch((err) => {
        console.error("Failed to get recent documents, ", err);
        if (err.message === "Unauthorized") {
          dispatchAuthUser({ type: "remove" });
        }
      });

    getFavoriteEntities(user.accessToken, connectionUrl)
      .then((data) => {
        setCategories((prev) => {
          return prev.map(cat => {
            if (cat.value === "favorites") {
              return {
                ...cat,
                entities: data,
              }
            }
            return cat;
          })
        });
      })
      .catch((err) => {
        console.error("Failed to get favorite documents, ", err);
        if (err.message === "Unauthorized") {
          dispatchAuthUser({ type: "remove" });
        }
      });
  }, []);

  function handleEntitySelection(entityID) {
    getSelectedEntityDocs(entityID);
    setSelectedEntity([
      ...categories.map(cat => cat.entities).flat(),
      ...searchResults,
    ].find(a => a.entityID === entityID));
    setShowDocumentView(true);
  }

  function getSelectedEntityDocs(entityID) {
    console.log("Getting documents for entity: ", entityID);
    getDocuments(user.accessToken, connectionUrl, entityID)
      .then((data) => {
        setDocumentList(data.map(doc => {
          return {
            ...doc,
            checked: false,
          }
        }));
      })
      .catch((err) => {
        console.error("Failed to get documents, ", err);
        if (err.message === "Unauthorized") {
          dispatchAuthUser({ type: "remove" });
        }
      });
  }

  function selectDocument(attachment) {
    setDocumentList((prev) => {
      return prev.map(a => {
        if (a.uniqueID === attachment.uniqueID) {
          return {
            ...a,
            checked: !a.checked,
          };
        }
        return a;
      })
    })
  }

  function selectAllDocuments() {
    const isChecked = documentList.every(a => a.checked);

    setDocumentList((prev) => {
      return prev.map(a => {
        return {
          ...a,
          checked: !isChecked,
        };
      })
    })
  }

  async function addAttachmentsToEmail() {
    const docs = documentList.filter(a => a.checked);
    setFeedbackMessage(initialFeedbackMessage);

    for (const doc of docs) {
      const { blob, extension } = await downloadDocument(user.accessToken, connectionUrl, doc.uniqueID);
      const fileName = doc.xmlDescription.text + "." + extension;

      addAttachmentToEmail(blob, fileName)
        .then(() => setFeedbackMessage((prev) => {
          return {
            ...prev,
            success: {
              title: attachSuccess,
            },
          }
        }))
        .catch((err) => {
          setFeedbackMessage((prev) => {
            return {
              ...prev,
              error: {
                title: attachError,
                list: [...prev.error.list, { fileName: fileName, message: err }],
              },
            }
          })
          console.error(`Failed to add attachment: ${fileName}, error: ${err}`);
          if (err.message === "Unauthorized") {
            dispatchAuthUser({ type: "remove" });
          }
        });
    };
  }

  return (
    <div className={styles.mainDiv}>
      {!showDocumentView ? (
        <div className={styles.entitySelection}>
          <Body1Strong className={styles.instruction}>{selectEntityToSearchDoc}</Body1Strong>
          <Categories
            selectedEntity={selectedEntity}
            handleEntitySelection={handleEntitySelection}
            categories={categories}
            categoriesOpen={categoriesOpen}
            setCategoriesOpen={setCategoriesOpen}
          />
          <EntitySearch
            searchResults={searchResults}
            setSearchResults={setSearchResults}
            selectedEntity={selectedEntity}
            handleEntitySelection={handleEntitySelection}
            setCategoriesOpen={setCategoriesOpen}
          />
        </div>
      ) : (
        <div className={styles.documentView}>
          <div className={styles.documentViewNavbar}>
            <Button
              onClick={() => {
                setSelectedEntity(null);
                setShowDocumentView(false);
                setFeedbackMessage(initialFeedbackMessage);
              }}
              appearance="subtle"
              icon={<ArrowLeft24Regular />}
            />
            <Body1Strong>{selectedEntity.entityName}</Body1Strong>
          </div>
          <Checkbox
            className={styles.selectAllCheckbox}
            onChange={() => selectAllDocuments()}
            checked={documentList.every(a => a.checked)}
            label="Select all documents"
          />
          <div className={styles.documents}>
            {documentList.map(doc => (
              <Checkbox
                onChange={() => selectDocument(doc)}
                checked={documentList.find((a) => a.uniqueID === doc.uniqueID).checked}
                label={doc.xmlDescription.text}
                key={doc.uniqueID}
              />
            ))}
          </div>
          <div className={styles.btnAndMessage}>
            <Button
              onClick={addAttachmentsToEmail}
              className={styles.attach}
              appearance="primary"
              disabled={!selectedEntity || documentList.every(a => !a.checked)}
              shape="square"
            >
              {attach}
            </Button>
            <FeedbackMessage feedbackMessage={feedbackMessage} />
          </div>
        </div>
      )}
    </div>
  );
};

ComposeEmailPane.propTypes = {};

export default ComposeEmailPane;
