import * as React from "react";
import PropTypes from "prop-types";
import { Image, makeStyles, shorthands } from "@fluentui/react-components";

const useStyles = makeStyles({
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "10px",
    "> div": {
      width: "80%",
      ...shorthands.borderBottom("3px", "solid", "#bbbbbb"),
    },
  },
  headerImage: {
    width: "90%",
  },
});

const Header = ({ logo }) => {
  const styles = useStyles();

  return (
    <div className={styles.header}>
      <div>
        <Image className={styles.headerImage} src={logo} alt="logo" />
      </div>
    </div>
  );
};

Header.propTypes = {
  logo: PropTypes.string,
};

export default Header;
