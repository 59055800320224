export const ACCESS_TOKEN_REFRESH_INTERVAL = 5 * 60 * 1000;  // every 5 min

// Products
const ENGLOBE_PRODUCT_ID = "{0000-442675EF-C887-461F-96B1-E8ADD3FD05E8}";
const GLOBALACT_PRODUCT_ID = "{0000-7669D8AC-F1C0-4EC7-84AF-285C71007A11}";

export const ENGLOBE = "englobe";
const GLOBALACT = "globalact";

export const CONNECTION_URL_REGEX = /^https:\/\/(englobe|globalact)-[a-zA-Z0-9]+(\.corporatek\.net)$/;


// Categories
export const SHORTCUT_CATEGORIES = [
  { title: "recent", value: "recent" },
  { title: "favorites", value: "favorites" },
];


// Entities
export const ENTITY_TYPES = [
  { title: "businessEntities", value: "business", icon: "business.png" },
  { title: "persons", value: "person", icon: "person.png" },
  { title: "contracts", value: "contract", icon: "contract.png" },
];

export const COMPONENT_BUSINESS_ENTITY = "{0000-1D8A008E-9487-4771-BAA5-A9AE1B407DE0}";
export const COMPONENT_PERSON = "{0000-28FA3B93-0B49-4F63-AE70-079B9C8B4840}";
export const COMPONENT_CONTRACT = "{0000-F131998D-1716-43D5-9C73-B7372089DADA}";


// Languages
export const LANGUAGES = [
  { key: "en", text: "English" },
  { key: "fr", text: "Français" },
  { key: "es", text: "Español" },
  { key: "de", text: "Dutch" },
  { key: "ja", text: "日本語" },
];
export const DEFAULT_LANGUAGE = "en";


// Entity results
export const RECENTS_FAVORITES_LIMIT = 5;
export const SEARCH_RESULTS_LIMIT = 20;


// File upload to IntelliAct
export const UNSUPPORTED_FILE_EXTENSIONS = new Set(["ics"]);  // TODO: Add more
