import * as React from "react";
import { useState, useContext, useEffect } from "react";
import { Button, Select, makeStyles, Input, MessageBar, shorthands } from "@fluentui/react-components";
import PropTypes from "prop-types";
import Header from "./Header";
import Footer from "./Footer";
import englobeLogo from "../../assets/englobe-logo.png";
import globalactLogo from "../../assets/globalact-logo.png";
import { login, validateConnectionUrl } from "../authentication";
import translate from "../translations/login-dictionary/translator.mjs";
import { LanguageContext, AuthUserDispatchContext } from "../contexts";
import { setAddInSettings } from "../../commands/commands.js";
import { ENGLOBE, LANGUAGES } from "../constants";

const product = process.env.PRODUCT;

const useStyles = makeStyles({
  mainDiv: {
    minHeight: "100dvh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundImage: "linear-gradient(#ffffff, #ccc)",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
  },
  formContent: {
    width: "210px",
    marginTop: "60px",
    marginBottom: "10px",
    marginLeft: "40px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "> *": {
      width: "100%",
    },
  },
  formTitle: {
    ...shorthands.borderBottom("1px", "solid", "#999"),
    "> div": {
      fontSize: "22px",
      fontWeight: "400",
      color: "#999",
      marginBottom: "10px",
    },
  },
  formInput: {
    display: "flex",
    flexDirection: "column",
    marginTop: "15px",
    "> span": {
      height: "32px",
      marginTop: "15px",
    },
    "> span:not(:first-child)": {
      paddingLeft: "12px",
      paddingRight: "12px",
    },
  },
  formSubmit: {
    height: "32px",
    marginTop: "30px",
    marginBottom: "15px",
    fontSize: "14px",
  },
  errorMessage: {
    whiteSpace: "normal",
    ...shorthands.padding("6px", "12px"),
  },
});

const Login = ({ setLanguage, setConnectionUrlCtx, connUrl, uId }) => {
  const dispatchAuthUser = useContext(AuthUserDispatchContext);
  const language = useContext(LanguageContext);

  const [connectionUrl, setConnectionUrl] = useState(connUrl ?? "");
  const [userId, setUserId] = useState(uId ?? "");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const styles = useStyles();

  const formTitle = translate(language, "loginGroup", product === ENGLOBE ? "signInEnGlobe" : "signInGlobalAct");
  const connectionUrlPlaceholder = translate(language, "loginGroup", "connectionURL");
  const userIdPlaceholder = translate(language, "loginGroup", "userName");
  const passwordPlaceholder = translate(language, "loginGroup", "password");
  const formSubmit = translate(language, "loginGroup", "signIn");

  useEffect(() => {
    setErrorMessage("");
  }, [language]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      signIn();
    }
  };

  const signIn = async () => {
    if (!connectionUrl || connectionUrl.length === 0) {
      setErrorMessage(translate(language, "loginGroup", "connectionURLRequired"));
      return;
    }
    if (!await validateConnectionUrl(connectionUrl)) {
      setErrorMessage(translate(language, "loginGroup", "wrongConnectionURL"));
      return;
    }
    if (!userId || userId.length === 0) {
      setErrorMessage(translate(language, "loginGroup", "validateUsername"));
      return;
    }
    if (!password || password.length === 0) {
      setErrorMessage(translate(language, "loginGroup", "validatePassword"));
      return;
    }

    setConnectionUrlCtx(connectionUrl);

    // Save connection details for future use
    setAddInSettings({
      language,
      connectionUrl,
      userId,
    });

    login(userId, password, connectionUrl)
      .then((data) => {
        dispatchAuthUser({ type: "set", payload: data });
      })
      .catch((error) => {
        console.error(error);
        switch (error.status) {
          case 501:
          case 503:
            setErrorMessage(translate(language, "loginGroup", "serverError"));
            break;
          default:
            setErrorMessage(translate(language, "loginGroup", "loginFailed"));
            break;
        }
      });
  };

  return (
    <div className={styles.mainDiv}>
      <div className={styles.mainContent}>
        <Header logo={product === ENGLOBE ? englobeLogo : globalactLogo} />
        <div className={styles.formContent}>
          <div className={styles.formTitle}>
            <div>{formTitle}</div>
          </div>
          <div className={styles.formInput}>
            <Select defaultValue={language} onChange={(e) => setLanguage(e.target.value)}>
              {LANGUAGES.map((option) => (
                <option key={option.key} value={option.key}>
                  {option.text}
                </option>
              ))}
            </Select>
            <Input
              placeholder={connectionUrlPlaceholder}
              value={connectionUrl}
              onChange={(e) => setConnectionUrl(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <Input
              placeholder={userIdPlaceholder}
              value={userId}
              onChange={(e) => setUserId(e.target.value)}
              onKeyDown={handleKeyDown}
            />
            <Input
              placeholder={passwordPlaceholder}
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </div>
          <Button className={styles.formSubmit} appearance="primary" shape="square" size="large" onClick={signIn}>
            {formSubmit}
          </Button>
          {errorMessage && (
            <MessageBar className={styles.errorMessage} intent="error" shape="square">
              {errorMessage}
            </MessageBar>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

Login.propTypes = {
  setLanguage: PropTypes.func,
  setConnectionUrlCtx: PropTypes.func,
  connUrl: PropTypes.string,
  uId: PropTypes.string,
};

export default Login;
