const dictionary = {
  en: {
    loginGroup: {
      clientAccessPortal: "Client Access Portal",
      connectionURL: 'Connection URL',
      connectionURLRequired: 'Connection URL is required',
      wrongConnectionURL: 'Wrong Connection URL',
      userName: "User ID",
      password: "Password",
      signIn: "Sign in",
      signInGlobalAct: "Sign in to GlobalAct",
      signInEnGlobe: "Sign in to EnGlobe",
      copyrightInfo: " © 2024 Corporatek Inc. All Rights Reserved",
      validateUsername: "User ID is required.",
      validatePassword: "Password is required.",
      loginFailed: "Wrong User ID or Password",
      forgotPassword: "Forgot your password?",
      forgotPasswordDialog: "Enter your email address and press Send to receive password reset instructions by email.",
      emailAddress: "Email address",
      yes: "Yes",
      no: "No",
      btnOk: "OK",
      btnClose: "Close",
      invalidEmail: "Invalid email address!",
      notReset:
        "The system was unable to reset your password. Please contact your system administrator for assistance.",
      resetSuccessful:
        "The instructions to reset your password were sent to you by email if the email you provided exists.",
      resetFailed: "Authentication failed.",
      currPassword: "Current Password",
      newPassword1: "New Password",
      newPassword2: "Confirm New Password",
      changePassword: "Change Password",
      passwordExpired: "Your password has expired. Please change your password.",
      badOldPass: "Incorrect Old Password",
      notIdentical: "New password and its confirmation are not identical",
      btnSaveAndClose: "Change",
      btnCancel: "Cancel",
      btnSend: "Send",
      operationInProgress: "Operation in progress...",
      serverOffline: "Server is offline, contact system administrator",
      serverError: "Server Error, contact system administrator",
      signInWithWindows: "Sign in with my Organizational Account",
      changeConnectionModeAD: "Sign in using my Organizational Account",
      changeConnectionModePassword: "Sign in with a User ID and Password",
      continue: "Continue",
      twoFactorCode: "Two Factor Authentication Code",
      invalidTwoFactorCode: "Invalid Authentication Code",
      twoFactorSecret: "Two Factor Secret",
      multipleActiveSessions: "Multiple Active Sessions",
      activeSessionRunnning: "There is an active session already running.",
      activeSessionQuestion: "Do you want to close it and continue?",
      loading: "Loading...",
      loadingEnvironment: "Loading environment...",
      fillCurrentPassword: "Fill Current password",
      setPassword: "Set Password",
      requestAccess: "Don't have an account?",
      requestAccessForm: "Request Access Form",
      submitTheFormBelow:
        "Submit the form below to request access. You will receive an email once access has been approved.",
      requiredField: "This is a required field.",
      newPasswordRequiredField: "New Password is a required field.",
      submitRequest: "Submit Request",
      submitRequestEmailSent: "We've sent you an email.  Enter the verification code in the email to proceed.",
      verificationCode: "Verification Code",
      verify: "Verify",
      registrationSubmitted: "Your registration request has been submitted.",
      error_deactivated: "This account doesn't exists or is deactivated.",
      error_forceSso: "Connection via Username/Password is disabled. Sign in with your Organizational Account instead.",
      preparingEnvironment: "Preparing Environment",
      setup2Fa: "Setup two-factor authentication",
      scanQrCode:
        "Scan the QR code below with an authentication application, such as Google Authenticator, on your phone.",
      manualQrCode: "If you can't scan the QR Code, enter the following setup key in your authentication application:",
    },
    security: {
      accessDenied: "Access denied",
      dontHaveRights:
        "You do not have the rights to perform this action. Please contact your system administrator for more information.",
      userMustchangePwd: "User must change password at next login",
      closeAllUserActiveSession: "Close all active sessions for this user",
    },
    dashboardGroup: {
      oneYearAgo: "1 year ago",
      oneDayAgo: "1 day ago",
      yearsAgo: "{0} years ago",
      monthsAgo: "{0} months ago",
      daysAgo: "{0} days ago",
      oneMinuteAgo: "1 minute ago",
      oneHourAgo: "1 hour ago",
      hoursAgo: "{0} hours ago",
      lessThanMinuteAgo: "< 1 minute ago",
      minutesAgo: "{0} minutes ago",
    },
    fileGroup: {
      archived: "Archived",
      addToFavorites: "Add To Favorites",
      closeFile: "Close",
      removeFromFavorites: "Remove From Favorites",
      activeWindows: "Active Windows",
    },
    dialogGroup: {
      help: "Help",
    },
    alertMeGroup: {
      never: "Never",
      whenItemsModified: "When items are modified",
      whenItemsDeleted: "When items are deleted",
      whenAnythingChanges: "When anything changes",
      whenNewItemsAreAdded: "When new items are added",
      whenChangesOnMyItems: "When someone changes an item created by me",
    },
    calendarGroup: {
      january: "January",
      february: "February",
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December",
    },
    durationGroup: {
      years: "year(s)",
      months: "month(s)",
      weeks: "week(s)",
      days: "day(s)",
      hours: "hour(s)",
      minutes: "minute(s)",
    },
  },
  fr: {
    loginGroup: {
      clientAccessPortal: "Portail d'accès client",
      connectionURL: 'URL de connexion',
      connectionURLRequired: 'L\'URL de connexion est requise',
      wrongConnectionURL: 'Mauvaise URL de connexion',
      userName: "Identifiant de l'utilisateur",
      password: "Mot de passe",
      signIn: "Connexion",
      signInGlobalAct: "Connexion au système GlobalAct",
      signInEnGlobe: "Connexion au système EnGlobe",
      copyrightInfo: " © 2024 Corporatek Inc. Tous droits réservés",
      validateUsername: "L'identifiant de l'utilisateur est requis.",
      validatePassword: "Le mot de passe est requis.",
      loginFailed: "Mauvais identifiant utilisateur ou mot de passe",
      forgotPassword: "Avez-vous oublié votre mot de passe ?",
      forgotPasswordDialog:
        "Entrez votre adresse e-mail et appuyez sur Envoyer pour recevoir les instructions de réinitialisation du mot de passe par e-mail.",
      emailAddress: "Courriel",
      yes: "Oui",
      no: "Non",
      btnOk: "Ok",
      btnClose: "Fermer",
      invalidEmail: "Adresse courriel invalide!",
      notReset:
        "Le système n’a pas réussi à changer le mot de passe. Veuillez consulter l’administrateur du système pour résoudre ce problème.",
      resetSuccessful:
        "Les instructions pour réinitialiser votre mot de passe vous ont été envoyées par e-mail si l’adresse e-mail que vous avez fournie existe.",
      resetFailed: "Authentification échouée",
      currPassword: "Mot de passe actuel",
      newPassword1: "Nouveau mot de passe",
      newPassword2: "Confirmer le nouveau mot de passe",
      changePassword: "Changer le mot de passe",
      passwordExpired: "Votre mot de passe a expiré. S'il vous plaît changer votre mot de passe.",
      badOldPass: "Ancien mot de passe invalide",
      notIdentical: "Le nouveau mot de passe et sa confirmation ne sont pas identiques",
      btnSaveAndClose: "Changer",
      btnCancel: "Annuler",
      btnSend: "Envoyer",
      operationInProgress: "Opération en cours d’exécution...",
      serverOffline: "Le serveur est déconnecté , veuillez contacter l'administrateur du système",
      serverError: "Erreur de serveur, contactez l'administrateur système",
      signInWithWindows: "Se connecter à l'aide de votre compte d'entreprise",
      changeConnectionModeAD: "Connexion avec mon compte Windows",
      changeConnectionModePassword: "Connexion avec un identifiant et un mot de passe",
      continue: "Continuer",
      twoFactorCode: "Code d'authentification à deux facteurs",
      invalidTwoFactorCode: "Code d'authentification non valide",
      twoFactorSecret: "Two Factor Secret",
      multipleActiveSessions: "Plusieurs sessions Actives",
      activeSessionRunnning: "Une session active est déjà en cours d'exécution.",
      activeSessionQuestion: "Voulez-vous le fermer et continuer?",
      loading: "Chargement...",
      loadingEnvironment: "Chargement de l'environnement...",
      fillCurrentPassword: "Remplir mot de passe actuel",
      setPassword: "Définir le mot de passe",
      requestAccess: "Vous n'avez pas de compte?",
      requestAccessForm: "Formulaire de demande d’accès",
      submitTheFormBelow:
        "Soumettez le formulaire ci-dessous pour demander l’accès. Vous recevrez un courriel une fois l’accès approuvé.",
      requiredField: "Champ obligatoire.",
      newPasswordRequiredField: "Nouveau mot de passe est un champ obligatoire.",
      submitRequest: "Soumettre votre demande",
      submitRequestEmailSent:
        "Nous vous avons envoyé un e-mail.  Entrez le code de vérification dans l’e-mail pour continuer.",
      verificationCode: "Code de vérification",
      verify: "Vérifier",
      registrationSubmitted: "Votre demande d’inscription a été soumise.",
      error_deactivated: "Ce compte n'existe pas ou est désactivé.",
      error_forceSso:
        "La connexion via le nom d'utilisateur/mot de passe est désactivée. Connectez-vous  avec votre compte d'entreprise.",
      preparingEnvironment: "Préparation de l'environnement",
      setup2Fa: "Configurer l'authentification à deux facteurs",
      scanQrCode:
        "Scannez le code QR ci-dessous avec une application d'authentification, telle que Google Authenticator, sur votre téléphone.",
      manualQrCode:
        "Si vous ne parvenez pas à scanner le code QR, entrez la clé de configuration suivante dans votre application d’authentification:",
    },
    security: {
      accessDenied: "Accès refusé",
      dontHaveRights: "Vous n'avez pas la permission d'exécuter cette action. Contactez l'administrateur de système.",
      userMustchangePwd: "L'utilisateur doit changer de mot de passe à la prochaine connexion",
      closeAllUserActiveSession: "Fermer toutes les sessions actives pour cet utilisateur",
    },
    dashboardGroup: {
      oneYearAgo: "il y a {0} an",
      oneDayAgo: "il y a 1 jour",
      yearsAgo: "il y a {0} années",
      monthsAgo: "il y a {0} mois",
      daysAgo: "il y a {0} jours",
      hoursAgo: "il y a {0} heures",
      oneMinuteAgo: "il y a 1 minute",
      oneHourAgo: "il y a 1 heure",
      lessThanMinuteAgo: "il y a moins d'une minute",
      minutesAgo: "il y a {0} minutes",
    },
    fileGroup: {
      archived: "Archivé",
      addToFavorites: "Ajouter aux favoris",
      closeFile: "Fermer",
      removeFromFavorites: "Retirer des favoris",
      activeWindows: "Fenêtres actives",
    },
    dialogGroup: {
      help: "Aide",
    },
    alertMeGroup: {
      never: "Jamais",
      whenItemsModified: "Quand des éléments sont modifiés",
      whenItemsDeleted: "Quand des éléments sont supprimés",
      whenAnythingChanges: "Quand une modification a lieu",
      whenNewItemsAreAdded: "Quand des éléments sont ajoutés",
      whenChangesOnMyItems: "Quand quelqu'un modifie un élément créé par moi",
    },
    calendarGroup: {
      january: "Janvier",
      february: "Février",
      march: "Mars",
      april: "Avril",
      may: "Mai",
      june: "Juin",
      july: "Juillet",
      august: "Août",
      september: "Septembre",
      october: "Octobre",
      november: "Novembre",
      december: "Décembre",
    },
    durationGroup: {
      years: "année(s)",
      months: "mois(s)",
      weeks: "semaine(s)",
      days: "jour(s)",
      hours: "heure(s)",
      minutes: "minute(s)",
    },
  },
  es: {
    loginGroup: {
      clientAccessPortal: "Portal de acceso de cliente",
      connectionURL: 'URL de Conexión',
      connectionURLRequired: 'Se requiere URL de Conexión',
      wrongConnectionURL: 'URL de Conexión Incorrecta',
      userName: "Idenficador del utilizador",
      password: "Contraseña",
      signIn: "Conexión",
      signInGlobalAct: "Entrada en el sistema GlobalAct",
      signInEnGlobe: "Entrada en el sistema EnGlobe",
      copyrightInfo: " © 2024 Corporatek Inc. Todos los derechos reservados.",
      validateUsername: "Identificación de usuario requerida",
      validatePassword: "Contraseña obligatoria",
      loginFailed: "Identificación de usuario o contraseña incorrecta",
      forgotPassword: "¿Olvidó su contraseña?",
      forgotPasswordDialog:
        "Ingrese su correo electrónico y presione Enviar para recibir instrucciones de restablecimiento de contraseña por correo electrónico.",
      emailAddress: "Correo electrónico",
      yes: "Sí",
      no: "No",
      btnOk: "Ok",
      btnClose: "Cerrar",
      invalidEmail: "Correo electrónico inválido",
      notReset: "No se puede cambiar la contraseña. Póngase en contacto con el administrador del sistema.",
      resetSuccessful:
        "Las instrucciones para restablecer su contraseña se le enviaron por correo electrónico si el correo electrónico que proporcionó existe.",
      resetFailed: "Error en la autenticación.",
      currPassword: "Contraseña corriente",
      newPassword1: "Nueva Contraseña",
      newPassword2: "Confirmar nueva contraseña",
      changePassword: "Cambia la contraseña",
      passwordExpired: "Su contraseña ha expirado. Por favor cambie su contraseña.",
      badOldPass: "La antigua contraseña no es válida",
      notIdentical: "La nueva contraseña y la confirmaciones no son las mismas",
      btnSaveAndClose: "Cambiar",
      btnCancel: "Cancelar",
      btnSend: "Enviar",
      operationInProgress: "Operación en progreso...",
      serverOffline: "Servidor no está en línea, póngase en contacto con el administrador del sistema",
      serverError: "Error del Servidor, póngase en contacto con el administrador del sistema",
      signInWithWindows: "Inicie sesión mediante su cuenta organizativa",
      changeConnectionModeAD: "Conexión con mi cuenta Windows",
      changeConnectionModePassword: "Conexión con un ID y una contraseña",
      continue: "Continuar",
      twoFactorCode: "Código de autenticación de dos factores",
      invalidTwoFactorCode: "Código de autenticación no válido",
      twoFactorSecret: "Two Factor Secret",
      multipleActiveSessions: "Múltiples Sesiones Activas",
      activeSessionRunnning: "Hay una sesión activa ya en ejecución.",
      activeSessionQuestion: "¿Quieres cerrarlo y continuar?",
      loading: "Cargando...",
      loadingEnvironment: "Cargando...",
      fillCurrentPassword: "Rellene la contraseña actual",
      setPassword: "Configurar la contraseña",
      requestAccess: "¿No tienes una cuenta?",
      requestAccessForm: "Formulario de acceso",
      submitTheFormBelow:
        "Envíe el siguiente formulario para solicitar acceso. Recibirá un correo electrónico una vez que se haya aprobado el acceso.",
      requiredField: "Campo obligatorio",
      newPasswordRequiredField: "Nueva contraseña es un campo obligatorio.",
      submitRequest: "Enviar solicitud",
      submitRequestEmailSent:
        "Te hemos enviado un correo electrónico.  Ingrese el código de verificación en el correo electrónico para continuar.",
      verificationCode: "Código de verificación",
      verify: "Verificar",
      registrationSubmitted: "Su solicitud de registro ha sido enviada.",
      error_deactivated: "Esta cuenta no existe o está desactivada.",
      error_forceSso:
        "La conexión a través de nombre de usuario/contraseña está desactivada. En su lugar, inicia sesión con tu cuenta organizativa.",
      preparingEnvironment: "Preparando el entorno",
      setup2Fa: "Configurar la autenticación de dos factores",
      scanQrCode:
        "Escanea el código QR a continuación con una aplicación de autenticación, como Google Authenticator, en tu teléfono.",
      manualQrCode:
        "Si no puede escanear el código QR, ingrese la siguiente clave de configuración en su aplicación de autenticación:",
    },
    security: {
      accessDenied: "Acceso denegado",
      dontHaveRights:
        "Usted no esta autorizado a ejecutar esta acción. Por favor contacte el administrador del sistema.",
      userMustchangePwd: "El usuario debe cambiar la contraseña en el siguiente inicio de sesión",
      closeAllUserActiveSession: "Cerrar todas las sesiones activas de este usuario",
    },
    dashboardGroup: {
      oneYearAgo: "Hace {0} año",
      oneDayAgo: "Hace 1 día",
      yearsAgo: "Hace {0} años",
      monthsAgo: "Hace {0} meses",
      daysAgo: "Hace {0} días",
      oneMinuteAgo: "Hace 1 minuto",
      oneHourAgo: "Hace 1 horas",
      hoursAgo: "Hace {0} horas",
      lessThanMinuteAgo: "Hace menos de un minuto",
      minutesAgo: "Hace {0} minutos",
    },
    fileGroup: {
      archived: "Archivado",
      addToFavorites: "Agregar a los favoritos",
      closeFile: "Cerrar",
      removeFromFavorites: "Remover de favoritos",
      activeWindows: "Windows activo",
    },
    dialogGroup: {
      help: "Ayuda",
    },
    alertMeGroup: {
      never: "Nunca",
      whenItemsModified: "Cuando se modifican elementos",
      whenItemsDeleted: "Cuando se eliminen elementos",
      whenAnythingChanges: "Cuando algo cambie",
      whenNewItemsAreAdded: "Cuando se agreguen nuevos elementos",
      whenChangesOnMyItems: "Cuando alguien cambia un elemento creado por mí",
    },
    calendarGroup: {
      january: "Enero",
      february: "Febrero",
      march: "Marzo",
      april: "Abril",
      may: "Mayo",
      june: "Junio",
      july: "Julio",
      august: "Agosto",
      september: "Septiembre",
      october: "Octubre",
      november: "Noviembre",
      december: "Diciembre",
    },
    durationGroup: {
      years: "año(s)",
      months: "mes(es)",
      weeks: "semana(s)",
      days: "día(s)",
      hours: "hora(s)",
      minutes: "minuto(s)",
    },
  },
  de: {
    loginGroup: {
      clientAccessPortal: "Clientzugriffsportal",
      connectionURL: 'Verbindings-URL',
      connectionURLRequired: 'Verbindings-URL is vereist',
      wrongConnectionURL: 'Verkeerde Verbindings-URL',
      userName: "Benutzername",
      password: "Passwort",
      signIn: "Anmelden",
      signInGlobalAct: "Eingang zu GlobalAct",
      signInEnGlobe: "Eingang zu EnGlobe",
      copyrightInfo: " © 2024 Corporatek Inc. Alle Rechte vorbehalten",
      validateUsername: "Benutzername erforderlich",
      validatePassword: "Passwort wird benötigt",
      loginFailed: "Falsche Benutzername oder Passwort",
      forgotPassword: "Kennwort vergessen?",
      forgotPasswordDialog:
        "Geben Sie Ihre E-Mail-Adresse ein und klicken Sie auf Senden, um Anweisungen zum Zurücksetzen des Kennworts per E-Mail zu erhalten.",
      emailAddress: "Email-Adresse",
      yes: "Ja",
      no: "Nein",
      btnOk: "OK",
      btnClose: "Schließen",
      invalidEmail: "Ungültige E-Mail-Adresse",
      notReset:
        "Das System konnte Ihr Passwort nicht zurücksetzen. Bitte wenden Sie sich an Ihren Systemadministrator, um Unterstützung zu erhalten.",
      resetSuccessful:
        "Die Anweisungen zum Zurücksetzen Ihres Passworts wurden Ihnen per E-Mail zugesandt, wenn die von Ihnen angegebene E-Mail-Adresse existiert.",
      resetFailed: "Authentifizierung fehlgeschlagen",
      currPassword: "Aktuelles Passwort",
      newPassword1: "Neues Kennwort",
      newPassword2: "Neues Kennwort bestätigen",
      changePassword: "Passwort ändern",
      passwordExpired: "Dein Passwort ist abgelaufen. Bitte ändern Sie Ihr Passwort.",
      badOldPass: "Altes ungültiges Kennwort",
      notIdentical: "Das neue Passwort und seine Bestätigung sind nicht identisch",
      btnSaveAndClose: "Veränderung",
      btnCancel: "Abbrechen",
      btnSend: "Senden",
      operationInProgress: "In Bearbeitung ...",
      serverOffline: "Server ist offline, wenden Sie sich an den Systemadministrator",
      serverError: "Serverfehler, wenden Sie sich an den Systemadministrator",
      signInWithWindows: "Melden Sie sich mit Windows-Anmeldeinformationen an",
      changeConnectionModeAD: "Melden Sie sich mit Windows-Anmeldeinformationen an",
      changeConnectionModePassword: "Melden Sie sich mit Benutzer-ID und Passwort an",
      continue: "Fortsetzen",
      twoFactorCode: "Zwei-Faktor-Authentifizierungscode",
      invalidTwoFactorCode: "Ungültiger Authentifizierungscode",
      twoFactorSecret: "Zwei-Faktoren-Geheimnis",
      multipleActiveSessions: "Mehrere aktive Sitzungen",
      activeSessionRunnning: "Es wird bereits eine aktive Sitzung ausgeführt.",
      activeSessionQuestion: "Möchten Sie es schließen und fortfahren?",
      loading: "Laden...",
      loadingEnvironment: "Lade Umwelt...",
      fillCurrentPassword: "Vul Aktuelles Passwort",
      setPassword: "Stel Passwort",
      requestAccess: "Sie haben noch keinen Account?",
      requestAccessForm: "Zugangsformular anfordern",
      submitTheFormBelow:
        "Füllen Sie das untenstehende Formular aus, um den Zugang zu beantragen. Sie erhalten eine E-Mail, sobald der Zugriff genehmigt wurde.",
      requiredField: "Pflichtfeld",
      newPasswordRequiredField: "Neues Passwort ist ein obligatorisches Feld.",
      submitRequest: "Anfrage absenden",
      submitRequestEmailSent:
        "Wir haben Ihnen eine E-Mail geschickt.  Geben Sie den Bestätigungscode in die E-Mail ein, um fortzufahren.",
      verificationCode: "Verifizierungscode",
      verify: "Überprüfen",
      registrationSubmitted: "Ihre Registrierungsanfrage wurde übermittelt.",
      error_deactivated: "Dieses Konto existiert nicht oder ist deaktiviert.",
      error_forceSso:
        "Die Verbindung über Benutzername/Passwort ist deaktiviert. Melden Sie sich stattdessen mit Ihrem Organisationskonto an.",
      preparingEnvironment: "Umgebung vorbereiten",
      setup2Fa: "Zwei-Faktor-Authentifizierung einrichten",
      scanQrCode:
        "Scannen Sie den QR-Code unten mit einer Authentifizierungsanwendung wie Google Authenticator auf Ihrem Telefon.",
      manualQrCode:
        "Wenn Sie den QR-Code nicht scannen können, geben Sie den folgenden Setup-Schlüssel in Ihre Authentifizierungsanwendung ein:",
    },
    security: {
      accessDenied: "Zugriff verweigert",
      dontHaveRights:
        "Sie haben nicht die Rechte, diese Aktion auszuführen. Bitte wenden Sie sich an Ihren Systemadministrator, " +
        "um weitere Informationen zu erhalten.",
      userMustchangePwd: "Benutzer muss das Kennwort bei der nächsten Anmeldung ändern",
      closeAllUserActiveSession: "Schließen Sie alle aktiven Sitzungen für diesen Benutzer",
    },
    dashboardGroup: {
      oneYearAgo: "vor {0} Jahr",
      oneDayAgo: "Vor einem Tag",
      yearsAgo: "Vor {0} Jahre",
      monthsAgo: "vor {0} Monate",
      daysAgo: "Vor {0} Tagen",
      oneMinuteAgo: "Vor einer Minute",
      oneHourAgo: "Vor einer Stunde",
      hoursAgo: "vor {0} Stunden",
      lessThanMinuteAgo: "< Vor einer Minute",
      minutesAgo: "vor {0} Minuten",
    },
    fileGroup: {
      archived: "Archiviert",
      addToFavorites: "Zu Favoriten hinzufügen",
      closeFile: "Schließen",
      removeFromFavorites: "Von Favoriten entfernen",
      activeWindows: "Aktives Windows",
    },
    dialogGroup: {
      help: "Hilfe",
    },
    alertMeGroup: {
      never: "Nie",
      whenItemsModified: "Vorhandene Elemente werden geändert",
      whenItemsDeleted: "Element werden gelöscht",
      whenAnythingChanges: "Allen Änderungen",
      whenNewItemsAreAdded: "Neue Elemente werden hinzugefügt",
      whenChangesOnMyItems: "Wenn jemand einen Artikel von mir erstellt ändert",
    },
    calendarGroup: {
      january: "Januar",
      february: "Februar",
      march: "Abril",
      april: "April",
      may: "Mai",
      june: "Juni",
      july: "Juli",
      august: "August",
      september: "September",
      october: "Oktober",
      november: "November",
      december: "Dezember",
    },
    durationGroup: {
      years: "jahr(e)",
      months: "monat(e)",
      weeks: "woche(n)",
      days: "tag(e)",
      hours: "stunde(n)",
      minutes: "minute(ns)",
    },
  },
  ja: {
    loginGroup: {
      clientAccessPortal: "クライアントアクセスポータル",
      connectionURL: '接続URL',
      connectionURLRequired: '接続URLは必須です',
      wrongConnectionURL: '誤った接続URL',
      userName: "ユーザー名",
      password: "パスワード",
      signIn: "サインイン",
      signInGlobalAct: "GlobalActにサインインします",
      signInEnGlobe: "EnGlobeにサインインします",
      copyrightInfo: " © 2024 Corporatek Inc. 全著作権所有",
      validateUsername: "ユーザー ID が必要です.",
      validatePassword: "パスワードが必要です.",
      loginFailed: "間違ったユーザーIDまたはパスワード",
      forgotPassword: "パスワードを忘れた場合",
      forgotPasswordDialog:
        "メールアドレスを入力し、[送信]を押すと、パスワードのリセット手順が電子メールで受信されます。",
      emailAddress: "メール アドレス",
      yes: "はい",
      no: "いいえ",
      btnOk: "OK",
      btnClose: "閉じる",
      invalidEmail: "無効なメール アドレス!",
      notReset: "システムはパスワードをリセットできませんでした。システム管理者に連絡してください。",
      resetSuccessful: "パスワードをリセットする手順は、指定した電子メールが存在する場合に電子メールで送信されました。",
      resetFailed: "認証に失敗しました.",
      currPassword: "現在のパスワード",
      newPassword1: "新しいパスワード",
      newPassword2: "パスワードの確認入力",
      changePassword: "パスワードの変更",
      passwordExpired: "パスワードの有効期限が切れています。パスワードを変更してください。",
      badOldPass: "使用中のパスワードが違います",
      notIdentical: "新しいパスワードとその確認は同一ではありません",
      btnSaveAndClose: "変更する",
      btnCancel: "キャンセル",
      btnSend: "送る",
      operationInProgress: "操作は実行中です...",
      serverOffline: "サーバーがオフラインです。システム管理者に連絡してください",
      serverError: "サーバーエラー、システム管理者に連絡してください",
      signInWithWindows: "組織アカウントでサインインします",
      changeConnectionModeAD: "組織アカウントでサインインします",
      changeConnectionModePassword: "ユーザーIDとパスワードでサインインする",
      continue: "続行",
      twoFactorCode: "二要素認証コード",
      invalidTwoFactorCode: "無効な認証コード",
      twoFactorSecret: "二要素秘密",
      multipleActiveSessions: "複数のアクティブセッション",
      activeSessionRunnning: "すでに実行中のアクティブなセッションがあります。",
      activeSessionQuestion: "閉じて続行しますか？",
      loading: "読み込み中...",
      loadingEnvironment: "読み込み中...",
      fillCurrentPassword: "現在のパスワードを入力してください",
      setPassword: "パスワードの設定",
      requestAccess: "アカウントをお持ちではありませんか？",
      requestAccessForm: "アクセスフォームのリクエスト",
      submitTheFormBelow:
        "以下のフォームを送信して、アクセスをリクエストしてください。アクセスが承認されると、メールが届きます。",
      requiredField: "これは必要項目です。",
      newPasswordRequiredField: "新しいパスワードは必須フィールドです。",
      submitRequest: "要求を送信",
      submitRequestEmailSent: "メールをお送りしました。 メールに記載されている確認コードを入力して続行します。",
      verificationCode: "確認コード",
      verify: "確かめる",
      registrationSubmitted: "登録リクエストが送信されました。",
      error_deactivated: "あなたのアカウントが無効になっています。",
      error_forceSso:
        "ユーザー名/パスワードによる接続は無効になっています。代わりに、組織アカウントでサイン インします。",
      preparingEnvironment: "環境の準備",
      setup2Fa: "2 要素認証のセットアップ",
      scanQrCode:
        "Google 認証システムなどの認証アプリケーションを使用して、以下の QR コードをスマートフォンでスキャンします。",
      manualQrCode: "QR コードをスキャンできない場合は、認証アプリケーションで次のセットアップキーを入力します。",
    },
    security: {
      accessDenied: "アクセスは拒否されました",
      dontHaveRights: "このアカウントは存在しないか、無効になっています。",
      userMustchangePwd: "ユーザーは次回のログイン時にパスワードを変更する必要があります",
      closeAllUserActiveSession: "このユーザーのアクティブなセッションをすべて閉じる",
    },
    dashboardGroup: {
      oneYearAgo: "1 年前",
      oneDayAgo: "1 日前",
      yearsAgo: "{0} 年前",
      monthsAgo: "{0} か月前",
      daysAgo: "{0} 日前",
      oneMinuteAgo: "1 分前",
      oneHourAgo: "1 時間前",
      hoursAgo: "{0} 時間前",
      lessThanMinuteAgo: "< 1 分前",
      minutesAgo: "{0} 分前",
    },
    fileGroup: {
      archived: "アーカイブ済み",
      addToFavorites: "お気に入りに追加",
      closeFile: "閉じる",
      removeFromFavorites: "お気に入りから削除",
      activeWindows: "アクティブなWindows",
    },
    dialogGroup: {
      help: "ヘルプ",
    },
    alertMeGroup: {
      never: "なし",
      whenItemsModified: "アイテムが変更された場合",
      whenItemsDeleted: "アイテムが削除されたとき",
      whenAnythingChanges: "何かが変わったとき",
      whenNewItemsAreAdded: "新しいアイテムが追加されたとき",
      whenChangesOnMyItems: "自分が作成したアイテムを変更した場合",
    },
    calendarGroup: {
      january: "1 月",
      february: "2 月",
      march: "3 月",
      april: "4 月",
      may: "5 月",
      june: "6 月",
      july: "7 月",
      august: "8 月",
      september: "9 月",
      october: "10 月",
      november: "11 月",
      december: "12 月",
    },
    durationGroup: {
      years: "年(秒)",
      months: "月",
      weeks: "週",
      days: "曜日",
      hours: "時間",
      minutes: "分",
    },
  },
};

export default dictionary;
