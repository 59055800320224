const mime = require("mime-types");
import { COMPONENT_BUSINESS_ENTITY, COMPONENT_PERSON, COMPONENT_CONTRACT } from "./constants";

async function makeAuthenticatedRequest(accessToken, connectionUrl, endpoint, method = "GET", params) {
  const body = method === "GET" ? null : JSON.stringify(params);

  return fetch(connectionUrl + endpoint, {
    method,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "bearer " + accessToken,
    },
    body,
  })
    .then((response) => {
      if (response.status === 401) {
        throw new Error("Unauthorized");
      }
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response;
    });
}

function getComponentId(entity) {
  switch (entity) {
    case "business":
      return COMPONENT_BUSINESS_ENTITY;
    case "person":
      return COMPONENT_PERSON;
    case "contract":
      return COMPONENT_CONTRACT;
  }
}

export async function getRecentEntities(accessToken, connectionUrl) {
  return makeAuthenticatedRequest(
    accessToken,
    connectionUrl,
    "/api/api/intelliact/entities/recent",
  )
    .then((response) => response.json())
    .then((data) => JSON.parse(data))
    .then((data) => data);
}

export async function getFavoriteEntities(accessToken, connectionUrl) {
  return makeAuthenticatedRequest(
    accessToken,
    connectionUrl,
    "/api/api/intelliact/entities/favorites",
  )
    .then((response) => response.json())
    .then((data) => JSON.parse(data))
    .then((data) => data);
}

export async function searchEntities(accessToken, connectionUrl, entityType, query) {
  return makeAuthenticatedRequest(
    accessToken,
    connectionUrl,
    `/api/api/intelliact/entities/search?searchText=${encodeURIComponent(query)}&componentID=${getComponentId(entityType)}`,
  )
    .then((response) => response.json())
    .then((data) => JSON.parse(data))
    .then((data) => data);
}

export async function getDocuments(accessToken, connectionUrl, entityId) {
  return makeAuthenticatedRequest(
    accessToken,
    connectionUrl,
    `/api/api/intelliact/documents?entityID=${entityId}`,
  )
    .then((response) => response.json())
    .then((data) => JSON.parse(data))
    .then((data) => data);
}

export async function downloadDocument(accessToken, connectionUrl, docId) {
  return makeAuthenticatedRequest(
    accessToken,
    connectionUrl,
    `/api/api/intelliact/documents/${docId}/download`,
  )
    .then((response) => response.blob())
    .then((data) => {
      return { blob: data, extension: mime.extension(data.type) }
    });
}

export async function uploadDocument(accessToken, connectionUrl, entityId, file, fileName) {
  return new Promise((resolve, reject) => {
    const params = {
      entityID: entityId,
      name: fileName,
      content: file,
      effectiveDate: new Date().toISOString(),
    };
    console.log("uploadDocument", params);

    makeAuthenticatedRequest(
      accessToken,
      connectionUrl,
      "/api/api/intelliact/documents/upload",
      "POST",
      params,
    )
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
}
