import * as React from "react";
import ReadEmailPane from "./ReadEmailPane";
import ComposeEmailPane from "./ComposeEmailPane";
import { makeStyles } from "@fluentui/react-components";
import { paneType } from "../../commands/commands.js";
import { ContextsProvider } from "../contexts";

const useStyles = makeStyles({
  root: {
    minHeight: "100dvh",
    overflowWrap: "anywhere",
    display: "flex",
    flexDirection: "column",
  },
});

const App = (props) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <ContextsProvider>
        {paneType === "read" ? (
          <ReadEmailPane />
        ) : (
          <ComposeEmailPane />
        )}
      </ContextsProvider>
    </div>
  );
};

App.propTypes = {};

export default App;
