const dictionary = {
  en: {
    intelliactGroup: {
      recent: "Recent",
      favorites: "Favorites",
      businessEntities: "Business Entities",
      persons: "Persons",
      contracts: "Contracts",
      search: "Search",
      selectSavingEntity: "Select saving entity",
      searchIn: "Search in",
      selectEntityToSearchDoc: "Select an entity to search for a document",
      selectItemsToSave: "Select items to save",
      save: "Save",
      attachFile: "Attach file(s)",
      uploadSuccess: "Document(s) uploaded successfully",
      uploadError: "Failed to upload document(s)",
      attachSuccess: "Document(s) attached successfully",
      attachError: "Failed to attach document(s)",
      showMore: "Show more",
    },
  },
  fr: {
    intelliactGroup: {
      recent: "Recent",
      favorites: "Favorites",
      businessEntities: "Business Entities",
      persons: "Persons",
      contracts: "Contracts",
      search: "Search",
      selectSavingEntity: "Select saving entity",
      searchIn: "Search in",
      selectEntityToSearchDoc: "Select an entity to search for a document",
      selectItemsToSave: "Select items to save",
      save: "Save",
      attachFile: "Attach file(s)",
      uploadSuccess: "Document(s) uploaded successfully",
      uploadError: "Failed to upload document(s)",
      attachSuccess: "Document(s) attached successfully",
      attachError: "Failed to attach document(s)",
      showMore: "Show more",
    },
  },
  es: {
    intelliactGroup: {
      recent: "Recent",
      favorites: "Favorites",
      businessEntities: "Business Entities",
      persons: "Persons",
      contracts: "Contracts",
      search: "Search",
      selectSavingEntity: "Select saving entity",
      searchIn: "Search in",
      selectEntityToSearchDoc: "Select an entity to search for a document",
      selectItemsToSave: "Select items to save",
      save: "Save",
      attachFile: "Attach file(s)",
      uploadSuccess: "Document(s) uploaded successfully",
      uploadError: "Failed to upload document(s)",
      attachSuccess: "Document(s) attached successfully",
      attachError: "Failed to attach document(s)",
      showMore: "Show more",
    },
  },
  de: {
    intelliactGroup: {
      recent: "Recent",
      favorites: "Favorites",
      businessEntities: "Business Entities",
      persons: "Persons",
      contracts: "Contracts",
      search: "Search",
      selectSavingEntity: "Select saving entity",
      searchIn: "Search in",
      selectEntityToSearchDoc: "Select an entity to search for a document",
      selectItemsToSave: "Select items to save",
      save: "Save",
      attachFile: "Attach file(s)",
      uploadSuccess: "Document(s) uploaded successfully",
      uploadError: "Failed to upload document(s)",
      attachSuccess: "Document(s) attached successfully",
      attachError: "Failed to attach document(s)",
      showMore: "Show more",
    },
  },
  ja: {
    intelliactGroup: {
      recent: "Recent",
      favorites: "Favorites",
      businessEntities: "Business Entities",
      persons: "Persons",
      contracts: "Contracts",
      search: "Search",
      selectSavingEntity: "Select saving entity",
      searchIn: "Search in",
      selectEntityToSearchDoc: "Select an entity to search for a document",
      selectItemsToSave: "Select items to save",
      save: "Save",
      attachFile: "Attach file(s)",
      uploadSuccess: "Document(s) uploaded successfully",
      uploadError: "Failed to upload document(s)",
      attachSuccess: "Document(s) attached successfully",
      attachError: "Failed to attach document(s)",
      showMore: "Show more",
    },
  },
};

export default dictionary;
