import React from "react";
import { useContext, useState } from "react";
import {
  makeStyles,
  tokens,
  shorthands,
  RadioGroup,
  Radio,
  Subtitle2,
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Button,
} from "@fluentui/react-components";
import { CaretDownRight16Filled, ChevronDown16Filled } from "@fluentui/react-icons";
import PropTypes from "prop-types";
import { LanguageContext } from "../contexts";
import translate from "../translations/intelliact-dictionary/translator.mjs";
import LoadingItems from "./LoadingItems";
import { RECENTS_FAVORITES_LIMIT } from "../constants";

const useStyles = makeStyles({
  categories: {
    display: "flex",
    flexDirection: "column",
  },
  accordionHeader: {
    "> button": {
      ...shorthands.paddingInline("0"),
      minHeight: "auto",
    }
  },
  accordionPanel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    ...shorthands.padding("0", "8px", "0", "8px"),
  },
  categoryTitle: {
    color: tokens.colorNeutralForeground2BrandHover,
    ...shorthands.margin("4px", "0", "4px", "0"),
  },
  categoryEntity: {
    "> div": {
      marginTop: "0",
    },
    "> label": {
      paddingTop: "0",
    },
  },
  showMoreButton: {
    paddingLeft: "0",
  },
});

const Categories = ({
  selectedEntity,
  handleEntitySelection,
  categories,
  categoriesOpen,
  setCategoriesOpen,
}) => {
  const styles = useStyles();
  const language = useContext(LanguageContext);
  const [showingMore, setShowingMore] = useState(
    categories.map((cat) => {
      return {
        value: cat.value,
        showAll: false,
      };
    })
  );

  const RadioButtons = ({ entities }) => {
    return (
      <RadioGroup
        value={selectedEntity?.entityID}
        onChange={(e, data) => handleEntitySelection(data.value)}
      >
        {entities.map((entity) => (
          <Radio
            key={entity.entityID}
            className={styles.categoryEntity}
            value={entity.entityID}
            label={entity.entityName}
          />
        ))}
      </RadioGroup>
    );
  };

  const ShowMoreBtn = ({ category }) => {
    return (
      <Button
        className={styles.showMoreButton}
        onClick={() => setShowingMore((prev) => {
          return prev.map((cat) => {
            if (cat.value === category.value) {
              return { ...cat, showAll: true };
            }
            return cat;
          });
        })}
        appearance="transparent"
        icon={<ChevronDown16Filled />}
        iconPosition="after"
      >
        {translate(language, "intelliactGroup", "showMore")}
      </Button>
    );
  };

  return (
    <div className={styles.categories}>
      {categories.map((category) => (
        <Accordion
          multiple
          collapsible
          onToggle={(e, data) => setCategoriesOpen(data.openItems)}
          openItems={categoriesOpen}
          key={category.value}
        >
          <AccordionItem value={category.value}>
            <AccordionHeader
              className={styles.accordionHeader}
              expandIcon={<CaretDownRight16Filled
                primaryFill={tokens.colorNeutralForeground2BrandHover} />}
            >
              <Subtitle2 className={styles.categoryTitle}>{translate(language, "intelliactGroup", category.title)}</Subtitle2>
            </AccordionHeader>
            {category.entities ? (
              <AccordionPanel className={styles.accordionPanel}>
                {showingMore.find(cat => cat.value === category.value).showAll ? (
                  <RadioButtons entities={category.entities} />
                ) : (
                  <>
                    <RadioButtons entities={category.entities.slice(0, RECENTS_FAVORITES_LIMIT)} />
                    {category.entities.length > RECENTS_FAVORITES_LIMIT && (
                      <ShowMoreBtn category={category} />
                    )}
                  </>
                )}
              </AccordionPanel>
            ) : (
              <LoadingItems number={2} />
            )}
          </AccordionItem>
        </Accordion>
      ))}
    </div>
  );
};

Categories.propTypes = {
  selectedEntity: PropTypes.object,
  handleEntitySelection: PropTypes.func,
  categories: PropTypes.array,
  categoriesOpen: PropTypes.array,
  setCategoriesOpen: PropTypes.func,
};

export default Categories;
