import { CONNECTION_URL_REGEX } from "./constants";

export async function login(credUserID, credPassword, connectionUrl) {
  return fetch(connectionUrl + "/ids/connect/token", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Accept-Language": "EN",
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: new URLSearchParams({
      grant_type: "password",
      username: credUserID,
      password: credPassword,
      scope: "openid xtekUser offline_access",
      client_id: "xtekLocal",
      client_secret: "4tFTxc0u0LZvYjky8imx",
    }).toString(),
  })
    .then(function (response) {
      if (!response.ok) {
        return Promise.reject(response.status);
      }
      return response.json();
    })
    .then(function (data) {
      const expiresAt = new Date();
      expiresAt.setSeconds(expiresAt.getSeconds() + (data.expires_in - 100));

      return {
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
        expiresAt,
      };
    })
    .catch(function (error) {
      return Promise.reject(error);
    });
}

export async function refreshAccessToken(accessToken, refreshToken, connectionUrl) {
  return fetch(connectionUrl + "/ids/connect/token", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "bearer " + accessToken,
    },
    body: new URLSearchParams({
      grant_type: "refresh_token",
      refresh_token: refreshToken,
      scope: "openid xtekUser offline_access",
      client_id: "xtekLocal",
      client_secret: "4tFTxc0u0LZvYjky8imx",
    }).toString(),
  })
    .then(function (response) {
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response.json();
    })
    .then(function (data) {
      if (!data.refresh_token) {
        return Promise.reject("2FA is not currently supported in IntelliAct");
      }

      const expiresAt = new Date();
      expiresAt.setSeconds(expiresAt.getSeconds() + (data.expires_in - 100));

      return {
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
        expiresAt,
      };
    })
    .catch(function (error) {
      return Promise.reject(error);
    });
}

export async function validateConnectionUrl(connectionUrl) {
  if (CONNECTION_URL_REGEX.test(connectionUrl)) {
    return fetch(connectionUrl + "/api/api/status", {
      method: "GET",
    })
      .then((data) => data);
  }

  return false;
}
