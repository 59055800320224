import * as React from "react";
import {
  makeStyles,
  shorthands,
  MessageBar,
} from "@fluentui/react-components";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  feedbackMessage: {
    whiteSpace: "normal",
    ...shorthands.padding("12px", "12px"),
    marginBottom: "12px",
    "> div": {
      display: "flex",
      flexDirection: "column",
    },
  },
  feedbackItems: {
    ...shorthands.marginBlock("0", "0"),
    paddingInlineStart: "28px",
  },
});

export const initialFeedbackMessage = {
  error: { title: "", list: [] },
  success: { title: "" },
};

const FeedbackMessage = ({ feedbackMessage }) => {
  const styles = useStyles();

  if (feedbackMessage.error?.title) {
    return (
      <MessageBar className={styles.feedbackMessage} intent="error" shape="square">
        <div>
          {feedbackMessage.error.title}:
          <ul className={styles.feedbackItems}>
            {feedbackMessage.error.list.map(({ fileName, message }, index) => (
              <li key={index}>{fileName}: {message}</li>
            ))}
          </ul>
        </div>
      </MessageBar>
    );
  } else if (feedbackMessage.success?.title) {
    return (
      <MessageBar className={styles.feedbackMessage} intent="success" shape="square">
        {feedbackMessage.success.title}
      </MessageBar>
    );
  } else {
    return null;
  }
};

FeedbackMessage.propTypes = {
  feedbackMessage: PropTypes.shape({
    error: PropTypes.shape({
      title: PropTypes.string,
      list: PropTypes.arrayOf(PropTypes.shape({
        fileName: PropTypes.string,
        message: PropTypes.string,
      })),
    }),
    success: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
};

export default FeedbackMessage;
