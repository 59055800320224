import * as React from "react";
import { useState, useContext, useEffect } from "react";
import {
  makeStyles,
  tokens,
  shorthands,
  Button,
  Checkbox,
  Body1Strong,
  Divider,
} from "@fluentui/react-components";
import translate from "../translations/intelliact-dictionary/translator.mjs";
import { LanguageContext, AuthUserContext, ConnectionUrlContext, AuthUserDispatchContext } from "../contexts";
import { attachments, subject, downloadAttachment } from "../../commands/commands.js";
import { getRecentEntities, getFavoriteEntities, uploadDocument } from "../authenticated-requests.js";
import { SHORTCUT_CATEGORIES, UNSUPPORTED_FILE_EXTENSIONS } from "../constants.js";
import Categories from "./Categories.jsx";
import EntitySearch from "./EntitySearch";
import FeedbackMessage, { initialFeedbackMessage } from "./FeedbackMessage";

const useStyles = makeStyles({
  mainDiv: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    ...shorthands.margin("0", "12px", "12px", "12px"),
  },
  locationSelection: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
  },
  divider: {
    ...shorthands.marginBlock("16px", "16px"),
    ...shorthands.borderBottom("3px", "solid", tokens.colorNeutralStroke1),
  },
  action: {
    display: "flex",
    flexDirection: "column",
  },
  attachments: {
    marginLeft: "28px",
  },
  save: {
    ...shorthands.marginBlock("8px", "8px"),
  },
  instruction: {
    marginBottom: "8px",
  },
});

const ReadEmailPane = (props) => {
  const language = useContext(LanguageContext);
  const user = useContext(AuthUserContext);
  const connectionUrl = useContext(ConnectionUrlContext);
  const dispatchAuthUser = useContext(AuthUserDispatchContext);

  const [searchResults, setSearchResults] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState({ entityID: null });
  const [feedbackMessage, setFeedbackMessage] = useState(initialFeedbackMessage);
  const [attach, setAttach] = useState(
    attachments.map((a) => {
      return {
        ...a,
        disabled: UNSUPPORTED_FILE_EXTENSIONS.has(a.name.split(".").pop().toLowerCase()),
        checked: false,
      }
    })
  );

  const styles = useStyles();

  const selectSavingEntity = translate(language, "intelliactGroup", "selectSavingEntity");
  const selectItemsToSave = translate(language, "intelliactGroup", "selectItemsToSave");
  const submitBtnText = translate(language, "intelliactGroup", "save");
  const uploadSuccess = translate(language, "intelliactGroup", "uploadSuccess");
  const uploadError = translate(language, "intelliactGroup", "uploadError");

  const [categories, setCategories] = useState(SHORTCUT_CATEGORIES);
  const [categoriesOpen, setCategoriesOpen] = useState(categories.map(cat => cat.value));

  useEffect(() => {
    getRecentEntities(user.accessToken, connectionUrl)
      .then((data) => {
        setCategories((prev) => {
          return prev.map(cat => {
            if (cat.value === "recent") {
              return {
                ...cat,
                entities: data,
              }
            }
            return cat;
          })
        });
      })
      .catch((err) => {
        console.error("Failed to get recent documents, ", err);
        if (err.message === "Unauthorized") {
          dispatchAuthUser({ type: "remove" });
        }
      });

    getFavoriteEntities(user.accessToken, connectionUrl)
      .then((data) => {
        setCategories((prev) => {
          return prev.map(cat => {
            if (cat.value === "favorites") {
              return {
                ...cat,
                entities: data,
              }
            }
            return cat;
          })
        });
      })
      .catch((err) => {
        console.error("Failed to get favorite documents, ", err);
        if (err.message === "Unauthorized") {
          dispatchAuthUser({ type: "remove" });
        }
      });
  }, []);

  function handleEntitySelection(entityID) {
    setSelectedEntity([
      ...categories.map(cat => cat.entities).flat(),
      ...searchResults,
    ].find(a => a.entityID === entityID));
  }

  function selectAttachment(attachment) {
    setAttach((prev) => {
      return prev.map(a => {
        if (a.id === attachment.id) {
          return {
            ...a,
            checked: !a.checked,
          };
        }
        return a;
      })
    })
  }

  function selectAllAttachments() {
    const isChecked = attach.filter(a => !a.disabled).every(a => a.checked);

    setAttach((prev) => {
      return prev.map(a => {
        if (!a.disabled) {
          return {
            ...a,
            checked: !isChecked,
          };
        } else {
          return a;
        }
      })
    })
  }

  async function save() {
    setFeedbackMessage(initialFeedbackMessage);

    for (const attachment of attach.filter(a => a.checked)) {
      downloadAttachment(attachment.id, attachment.name)
        .then((file) => uploadDocument(user.accessToken, connectionUrl, selectedEntity.entityID, file, attachment.name))
        .then(() => setFeedbackMessage((prev) => {
          return {
            ...prev,
            success: {
              title: uploadSuccess,
            },
          }
        }))
        .catch((error) => {
          setFeedbackMessage((prev) => {
            return {
              ...prev,
              error: {
                title: uploadError,
                list: [...prev.error.list, { fileName: attachment.name, message: error }],
              },
            }
          })
          console.error(`Failed to upload document: "${attachment.name}", ${error}`);
          if (err.message === "Unauthorized") {
            dispatchAuthUser({ type: "remove" });
          }
        });
    }
  }

  return (
    <div className={styles.mainDiv}>
      <div className={styles.locationSelection}>
        <Body1Strong className={styles.instruction}>{selectSavingEntity}</Body1Strong>
        <Categories
          selectedEntity={selectedEntity}
          handleEntitySelection={handleEntitySelection}
          categories={categories}
          categoriesOpen={categoriesOpen}
          setCategoriesOpen={setCategoriesOpen}
        />
        <EntitySearch
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          selectedEntity={selectedEntity}
          handleEntitySelection={handleEntitySelection}
          setCategoriesOpen={setCategoriesOpen}
        />
      </div>
      <div className={styles.action}>
        <Divider className={styles.divider} />
        <Body1Strong className={styles.instruction}>{selectItemsToSave}</Body1Strong>
        <Checkbox
          onChange={() => selectAllAttachments()}
          checked={attach.every(a => a.checked)}
          disabled={attach.every(a => a.disabled)}
          label={subject}
        />
        <div className={styles.attachments}>
          {attach.map(attachment => (
            <Checkbox
              onChange={() => selectAttachment(attachment)}
              checked={attach.find((a) => a.id === attachment.id).checked}
              disabled={attachment.disabled}
              label={attachment.name}
              key={attachment.id}
            />
          ))}
        </div>
        <Button
          onClick={save}
          className={styles.save}
          appearance="primary"
          disabled={!selectedEntity.entityID || attach.every(a => !a.checked)}
          shape="square"
        >
          {submitBtnText}
        </Button>
        <FeedbackMessage feedbackMessage={feedbackMessage} />
      </div>
    </div>
  );
};

ReadEmailPane.propTypes = {};

export default ReadEmailPane;
